import React from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import getLocationDescriptor from "../../helpers/get-location-descriptor";

export default function (props) {
  const { token } = qs.parse(window.location.search);
  const { back, next, finish } = props;

  return (
    <div className="navigation mb-6">
      {back && (
        <div>
          <Link
            to={getLocationDescriptor(back, token)}
            className="btn btn-secondary"
          >
            <i className="icon-arrow-left2"></i>&nbsp;&nbsp;Anterior
          </Link>
        </div>
      )}
      {next && (
        <div>
          <Link
            to={getLocationDescriptor(next, token)}
            className="btn btn-primary"
          >
            Siguiente&nbsp;&nbsp;<i className="icon-arrow-right2"></i>
          </Link>
        </div>
      )}
      {finish}
    </div>
  );
}
