import React from "react";
import HBRCultures from "../../images/hbr-cultures.png";

export const CulturesModal = ({ handleClose, show, infoMap }) => {
  const showHideClassName = show ? "modal show-modal" : "modal hide-modal";
  return (
    <div className={showHideClassName}>
      <div className="modal-main modal-cultures">
        <h4 className="text-bold mb-2">Culturas</h4>

        <div className="row">
          <div className="hidden-xs col-sm-4">
            <img
              className="culture-map"
              src={HBRCultures}
              alt="Mapa de culturas"
            ></img>
          </div>

          <div className="col-xs-12 col-sm-8">
            {Object.keys(infoMap).map((c, i) => {
              return (
                <div key={i} className="item pt-1">
                  <p
                    className="text-justify"
                    dangerouslySetInnerHTML={{ __html: infoMap[c] }}
                  ></p>
                </div>
              );
            })}
          </div>
        </div>

        <button className="btn btn-primary mt-2" onClick={handleClose}>
          Cerrar
        </button>
      </div>
    </div>
  );
};

export const DISCModal = ({ handleClose, show, infoMap }) => {
  const showHideClassName = show ? "modal show-modal" : "modal hide-modal";
  return (
    <div className={showHideClassName}>
      <div className="modal-main modal-disc">
        <h4 className="text-bold mb-2">Perfil personal</h4>
        {Object.keys(infoMap).map((c, i) => {
          return (
            <div key={i} className="item pt-1">
              <p className="text-justify">
                <span className="text-bold">
                  {infoMap[c].name} ({c})
                </span>
                <br></br>
                <span>{infoMap[c].high}</span>
                <br></br>
                <span>{infoMap[c].low}</span>
              </p>
            </div>
          );
        })}
        <button className="btn btn-primary mt-2" onClick={handleClose}>
          Cerrar
        </button>
      </div>
    </div>
  );
};

export const ModelIndexesModal = ({ handleClose, show, infoMap }) => {
  const showHideClassName = show ? "modal show-modal" : "modal hide-modal";
  return (
    <div className={showHideClassName}>
      <div className="modal-main modal-indexes-model">
        <h4 className="text-bold mb-2">Indices del modelo</h4>
        {Object.keys(infoMap).map((c, i) => {
          return (
            <div key={i} className="item pt-1">
              <p
                className="text-justify"
                dangerouslySetInnerHTML={{ __html: infoMap[c] }}
              ></p>
            </div>
          );
        })}
        <button className="btn btn-primary mt-2" onClick={handleClose}>
          Cerrar
        </button>
      </div>
    </div>
  );
};

export const TextModal = ({ handleClose, show, text }) => {
  const showHideClassName = show ? "modal show-modal" : "modal hide-modal";
  return (
    <div className={showHideClassName}>
      <div className="modal-main">
        <p>{text}</p>
        <button className="btn btn-primary" onClick={handleClose}>
          Aceptar
        </button>
      </div>
    </div>
  );
};

export const LoadingModal = ({ show }) => {
  const showHideClassName = show ? "modal show-modal" : "modal hide-modal";
  return (
    <div className={showHideClassName}>
      <div className="modal-main modal-loading no-padding">
        <div className="loader"></div>
      </div>
    </div>
  );
};

export const GenericModal = ({
  type,
  name,
  desc,
  show,
  callback,
  cancelCallback,
}) => {
  const showHideClassName = show ? "modal show-modal" : "modal hide-modal";
  const renderSwitchModalIcon = (param) => {
    switch (param) {
      case "success":
        return <i className="icon-checkmark" />;
      case "error":
        return <i className="icon-cross" />;
      case "warning":
        return <i className="icon-warning" />;
      default:
        return <i className="icon-info" />;
    }
  };
  return (
    <div className={showHideClassName}>
      <div className="modal-main">
        <p className="title">
          {renderSwitchModalIcon(type)}
          {name}
        </p>
        <p className="description">{desc}</p>

        {cancelCallback && (
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <button className="btn btn-secondary" onClick={cancelCallback}>
                Cancelar
              </button>
            </div>
            <div className="col-xs-12 col-sm-6">
              <button className="btn btn-primary" onClick={callback}>
                Aceptar
              </button>
            </div>
          </div>
        )}

        {!cancelCallback && (
          <button className="btn btn-primary" onClick={callback}>
            Aceptar
          </button>
        )}
      </div>
    </div>
  );
};
