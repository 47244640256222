import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AdminMotivators from "./components/DataWrapper/adminmotivators";
import "./styles/index.scss";
import { properties } from "./properties";

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/admin" component={AdminMotivators} />
          {/*<Route exact path="/">{<Redirect to={properties.loginURL} />}</Route>*/}
          <Route
            exact
            path="/"
            component={() => {
              window.location.href = properties.loginURL;
              return null;
            }}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}
