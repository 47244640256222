import React, { useState } from "react";
import { TextModal } from "../Modals";

const FileUploader = (props) => {
  let fileReader;
  const [showLoadingModal, setShowModal] = useState(false);
  const [emailNumber, setEmailNumber] = useState(0);

  const handleClick = () => {
    document.getElementById("hiddenFileInput").click();
  };

  const handleChange = (event) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;

    const fileUploaded = event.target.files[0];

    fileReader.readAsText(fileUploaded);
    handleFile(fileUploaded);
  };

  const handleFile = (obj) => {
    var file = obj.name;
    var fileName = file.split("\\");
    document.getElementById("uploadBtn").innerHTML =
      fileName[fileName.length - 1];
  };

  const handleFileRead = (event) => {
    const content = fileReader.result;
    setEmailNumber(content.split("\n").length);
    setShowModal(true);
    props.onChange(content.split("\n"));
  };

  return (
    <div className="file-uploader">
      <TextModal
        show={showLoadingModal}
        handleClose={() => setShowModal(false)}
        text={`Se añadieron ${emailNumber} emails`}
      />
      
      <button id="uploadBtn" className="btn" onClick={handleClick}>
        Sube un fichero .txt con sus emails
      </button>
      
      <input
        type="file"
        id="hiddenFileInput"
        onChange={handleChange}
        accept="text/plain"
      />
    </div>
  );
};
export default FileUploader;
