import React, { Component } from "react";
import { getProjectName } from "../../helpers/converters";

export default class ParticipationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.data = props.data;
    this.state.country = props.country;
  }

  render() {
    return (
      this.state.data.by_project && (
        <div className="participation-table pl-2 pr-2">
          <div className="row participation-table-header">
            <div className="col-xs-8 pt-1 pb-1 text-bold">Proyecto</div>
            <div className="col-xs-4 pt-1 pb-1 text-bold">Respuestas</div>
          </div>

          {Object.keys(this.state.data.by_project)
            .sort()
            .map((item, index) => (
              <div className="row participation-table-row" key={index}>
                <div className="col-xs-8 pt-1 pb-1">
                  {getProjectName(item, this.state.country)}
                </div>
                <div className="col-xs-4 pt-1 pb-1">
                  {this.state.data.by_project[item]}
                </div>
              </div>
            ))}
        </div>
      )
    );
  }
}
