export const MAIL_DOMAIN = ".next@bbva.com";

export const DAYSOFWEEK = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const COUNTRY = [
  { id: "ES", title: "España" },
  //{ id: "MX", title: "México" },
];

export const COMPANY = [{ id: "bbva_next", title: "BBVA Next" }];

export const ADMIN_ROLE = "admin";

export const CAMPAIGN_TYPES_FRIENDLY_NAMES = {
  // salida: "Leaving",
  regular: "Next",
  // desasignado_entrada: "TiT entrada Staffing",
  // desasignado_salida: "TiT salida Staffing",
  // tit_vacante_solicitada: "TiT vacante solicitada",
  // tit_vacante_conseguida: "TiT vacante conseguida",
  // superado_periodo_prueba: "Welcome",
  // zoom: "Zoom",
  recruiting: "Talent Magnets",
};

export const CAMPAIGN_STATUS_FRIENDLY_NAMES = {
  created: "Creada",
  scheduled: "En curso",
  closed: "Finalizada",
};

export const SALARY_IMPROVEMENT = [
  { id: 0, title: "Se desconoce" },
  { id: 1, title: "Se desconoce" },
  { id: 2, title: "0-10%" },
  { id: 3, title: "20%-40%" },
  { id: 4, title: "40%-60%" },
  { id: 5, title: "60%-80%" },
  { id: 6, title: "80%-100%" },
];

export const YESNONSNC = [
  { id: 1, title: "No" },
  { id: 2, title: "Sí" },
  { id: 3, title: "Se desconoce" },
];

export const CULTURE_DESCRIPTIONS = {
  Learning:
    "<b>Learning</b> is characterized by exploration, expansiveness, and creativity. Work environments are inventive and open-minded places where people spark new ideas and explore alternatives. Employees are united by curiosity; leaders emphasize innovation, knowledge, and adventure.",
  Enjoyment:
    "<b>Enjoyment</b> is expressed through fun and excitement. Work environments are lighthearted places where people tend to do what makes them happy. Employees are united by playfulness and stimulation; leaders emphasize spontaneity and a sense of humor.",
  Results:
    "<b>Results</b> is characterized by achievement and winning. Work environments are outcome-oriented and merit-based places where people aspire to achieve top performance. Employees are united by a drive for capability and success; leaders emphasize goal accomplishment.",
  Authority:
    "<b>Authority</b> is defined by strength, decisiveness, and boldness. Work environments are competitive places where people strive to gain personal advantage. Employees are united by strong control; leaders emphasize confidence and dominance.",
  Purpose:
    "<b>Purpose</b> is exemplified by idealism and altruism. Work environments are tolerant, compassionate places where people try to do good for the long-term future of the world. Employees are united by a focus on sustainability and global communities; leaders emphasize shared ideals and contributing to a greater cause.",
  Caring:
    "<b>Caring</b> focuses on relationships and mutual trust. Work environments are warm, collaborative, and welcoming places where people help and support one another. Employees are united by loyalty; leaders emphasize sincerity, teamwork, and positive relationships.",
  Order:
    "<b>Order</b> is focused on respect, structure, and shared norms. Work environments are methodical places where people tend to play by the rules and want to fit in. Employees are united by cooperation; leaders emphasize shared procedures and time-honored customs.",
  Safety:
    "<b>Safety</b> is defined by planning, caution, and preparedness. Work environments are predictable places where people are risk-conscious and think things through carefully. Employees are united by a desire to feel protected and anticipate change; leaders emphasize being realistic and planning ahead.",
};

export const DISC_DESCRIPTIONS = {
  D: {
    name: "Dominancia",
    low: "Un perfil con D baja se define como acomodaticio/a y apacible, se muestra como una persona que evita los conflictos y que prefiere que le digan cómo hacer las cosas a ser ella quien dirija. Agradable y gustoso/a de hacer las cosas fáciles a los demás.",
    high: "Un perfil con D alta se define como enérgico/a y competitivo/a, directo/a y asertivo/a en la manera de expresarse y muy orientado/a a resultados. Le motiva el poder y la autoridad y tiene miedo a fracasar precisamente por su carácter emprendedor. Su estilo de liderazgo es directivo y autocrático.",
  },
  I: {
    name: "Influencia",
    low: "Un perfil con I baja se define como reservado/a y cohibido/a, lo que le muestra cómo una persona introvertida. Ese carácter serio le puede hacer parecer desconfiado/a y reflexivo/a. Es lógico/a, incisivo/a y se siente cómodo/a con los hechos más que con las palabras.",
    high: "Un perfil con I alta se define como influyente, amistoso/a y muy comunicativo/a. Le motiva el premio y el reconocimiento social. Positivo/a y persuasivo/a, evita el rechazo social. Su estilo de liderazgo es muy participativo/a y motivador/a.",
  },
  S: {
    name: "Estabilidad",
    low: "Un perfil con S baja se define como inquieto/a y activo/a, lo que le mantiene siempre alerta. Este ritmo alto le hace mostrarse como alguien inquieto/a e impulsivo/a a quien la rutina le puede aburrir. Le gusta el cambio y la novedad y es muy flexible.",
    high: "Un perfil con S alta se define como prudente, atento/a y amable. Le motiva la seguridad y se muestra atento/a y confiable ante los demás. Sus temores básicos son el cambio y la inseguridad. Le gusta trabajar en entornos predecibles donde tenga todo controlado y el ritmo del trabajo sea el adecuado a sus características.",
  },
  C: {
    name: "Cumplimiento",
    low: "Un perfil con C baja se define como firme en sus opiniones lo que le puede mostrar como alguien terco/a, de fuerte voluntad e inflexible. La claridad en sus ideas le hace una persona independiente y constante a la que le gusta hacer las cosas según sus normas.",
    high: "Un perfil con C alta se define como sistemático/a, preciso/a y lógico/a. Le motivan los procesos y la estandarización. Le gusta la exactitud y el perfeccionismo y evita siempre que puede el conflicto. Cuidadoso/a en su trabajo, se maneja bien en el cumplimiento de normas y procesos.",
  },
};

export const MODEL_INDEXES_DESCRIPTIONS = {
  Ext: "La <b>Motivación Extrínseca</b> proviene de fuentes externas a la persona. Son estímulos y recompensas externos que nos hacen movilizar energía para realizar algo.",
  Int: "La <b>Motivación Intrínseca</b> es el impulso natural e inherente a cada persona para buscar los desafíos y las nuevas posibilidades que la Teoría de la Autodeterminación asocia con el desarrollo cognitivo y social.",
  MI: "El <b>Meaning Index</b> describe la adecuación entre las habilidades del individuo y los retos asignados, de manera que la experiencia del empleado se caracteriza por la sensación de estar haciendo algo diferencial e ilusionante y esto conduzca a la consecución de objetivos extraordinarios.",
  PI: "El <b>Progress Index</b> incluye todos aquellos aspectos relacionados con la capacidad del empleado para tener la sensación de crecimiento y consecución de objetivos profesionales, tanto formales como informales.",
  FI: "El <b>Fit Index</b> describe entornos donde la confianza y el respeto, la resolución constructiva de conflictos, el sentido del humor y una sensación general de “estamos juntos en esto”, y la consecuente habilidad para colaborar de manera efectiva, formen parte del entorno de trabajo.",
};

export const DEPRECATED_PROJECT = "# Obsoleto #";

export const PROJECTS = {
  ES: [
    {
      id: 108,
      title: "Delivery-Business",
      children: [
        { id: 10810, title: "Delivery-Business" }
      ],
    },
    {
      id: 101,
      title: "ANS",
      children: [
        { id: 10110, title: "ANS" }
      ],
    },
    {
      id: 110,
      title: "Global Software Development",
      children: [
        { id: 11030, title: "Alpha Core Banking" },
        { id: 11031, title: "Developers Communities + W2E SW Dev Journeys + Testing + SW dev Policies & Requirements" },
        { id: 11032, title: "Security GSD + Core Banking SW2" }
      ],
    },
    {
      id: 102,
      title: "Architecture",
      children: [
        { id: 10270, title: "Batch Architectures & Databases" },
        { id: 10271, title: "PaaS Architecture + DataBus + Mainframe Arch. & Modernization" },
        { id: 10272, title: "Services Architecture" },
        { id: 10273, title: "Banking Architecture" },
        { id: 10274, title: "Data Technical Governance + DataX + DatioEvolution" },
        { id: 10275, title: "Middleware BPM + Middleware Gestión Documental" },
        { id: 10276, title: "Services + Cells + Labs & Blockchain" },
        { id: 10277, title: "Automation Deployment & Infrastructurure + Extensions & Reliability" },
        { id: 10278, title: "Monitoring, Observability & Telemetry" },
        { id: 10279, title: "CCoE GCP + CCoE AWS" },
        { id: 10280, title: "Banking Architecture Security" },
        { id: 10281, title: "Identity" },
        { id: 10282, title: "Platform Protection + Edge Security" },
        { id: 10283, title: "Extended Services + SeP" }
      ],
    },
    {
      id: 104,
      title: "CIB",
      children: [
        { id: 10411, title: "A&I CIB" },
        { id: 10412, title: "SW Delivery CIB" },
        { id: 10413, title: "SW Cross CIB" },
        { id: 10414, title: "ER&CA CIB" }
      ],
    },
    {
      id: 107,
      title: "Data engineering + AI Factory",
      children: [
        { id: 10710, title: "Data engineering + AI Factory" }
      ],
    },
    {
      id: 111,
      title: "Infrastructure & Operations",
      children: [
        { id: 11111, title: "IT Operations + Optimization & Transformation" },
        { id: 11112, title: "Compute" },
        { id: 11113, title: "Networking + Storage" },
        { id: 11114, title: "Networking & Workplace" },
        { id: 11115, title: "Processes & DevOps + Service & Information Focal Point" }
      ],
    },
    {
      id: 103,
      title: "CFE",
      children: [
        { id: 10321, title: "Architecture & Infrastructure & Corporate Security" },
        { id: 10322, title: "T&C" },
        { id: 10323, title: "GRM" },
        { id: 10324, title: "R&IC + Aud. Legal & Chairman Units" },
        { id: 10325, title: "Finance" }
      ],
    },
    {
      id: 109,
      title: "Engineering Spain",
      children: [
        { id: 10910, title: "Arquitectura" },
        { id: 10920, title: "Channels Systems" },
        { id: 10930, title: "Enterprise Systems + Enterprise Systems UX" },
        { id: 10931, title: "Asset Management SD" }
      ],
    },
    {
      id: 105,
      title: "Client Solutions Engineering",
      children: [
        { id: 10551, title: "Software Development Delivery Enterprise" },
        { id: 10552, title: "Software Development Delivery Retail" },
        { id: 10553, title: "Software Development Architecture & Security + Software Development Delivery Cross + Corporate Security" }
      ],
    },
    {
      id: 112,
      title: "Next - Enterprise",
      children: [
        { id: 11210, title: "Talent" },
        { id: 11220, title: "Talent Delivery" },
        { id: 11230, title: "Enterprise Ops" },
        { id: 11240, title: "Enterprise Tech&Data" },
      ],
    },
    {
      id: 113,
      title: "Desasignados",
      children: [
        { id: 11300, title: "Desasignados" },
      ],
    },
    {
      id: 114,
      title: "Otros",
      children: [
        { id: 11400, title: "Otros" },
      ],
    }    
  ],
};

export const PLANETS = {
  ES: [
    { id: 201, title: "Agile Practitioner" },
    { id: 203, title: "Communications" },
    { id: 204, title: "Consulting" },
    { id: 205, title: "Data Scientist" },
    { id: 206, title: "Finance" },
    { id: 207, title: "Functional Analyst" },
    { id: 208, title: "Infrastructure Specialist" },
    { id: 209, title: "People" },
    { id: 210, title: "Product Owner" },
    { id: 211, title: "QA" },
    { id: 212, title: "Research" },
    { id: 213, title: "Security Engineer" },
    { id: 214, title: "Software Crafter" },
    { id: 215, title: "Specialist" },
    { id: 216, title: "Devops" },
    { id: 217, title: "Team Lead" },
    { id: 219, title: "UX" },
    { id: 221, title: "Networking" },
    { id: 222, title: "Solution Architect" },
    { id: 223, title: "SRE" },
    { id: 224, title: "Operaciones" },
    { id: 225, title: "Delivery Manager" },
    { id: 226, title: "Academy" }
  ]
};

export const LEVELS = {
  ES: [
    { id: 1, title: "1" },
    { id: 2, title: "2" },
    { id: 3, title: "3" },
    { id: 4, title: "4" },
    { id: 5, title: "5" },
    { id: 6, title: "6" },
  ],
};

export const YESNO = [
  { id: 0, title: "No" },
  { id: 1, title: "Sí" },
];
