import React, { Component } from "react";
import axios from "axios";
import qs from "query-string";
import { Route, withRouter } from "react-router-dom";
import {
  redirectTo,
  redirectToLogin,
  setCookie,
} from "../../../helpers/cookies-manager";
import { GenericModal, LoadingModal } from "../../Modals";
import { getCookie } from "../../../helpers/cookies-manager";

// Routes
import CampaignList from "../../../routes/CampaignList";
import CampaignCreation from "../../../routes/CampaignCreation";
import CampaignDetail from "../../../routes/CampaignDetail";
import Error from "../../../routes/Error";

// Components
import Header from "../../Header";
import { properties } from "../../../properties";
const { error } = console;

class AdminMotivators extends Component {
  constructor(props) {
    super(props);

    let { id_token /*, access_token*/, error } = qs.parse(props.location.hash);

    if (!id_token) {
      // trying to use authToken saved previously at cookie
      id_token = getCookie("admin_mot_at");
    }

    if (error || !id_token) {
      this.hasError = true;
    }

    if (id_token) {
      setCookie("admin_mot_at", id_token, null);
    } else {
      // check if redirection required
      const requestedUrl = new URL(window.location.href);
      localStorage.setItem("redir", requestedUrl.pathname);

      this.showModal("info", "Se requiere nuevo Login", "", redirectToLogin);
    }

    this.state = {
      hasError: false,
      authToken: id_token,
      alreadyIdentified: false,

      /*
      country: false,
      employee: false,
      comments: "",
      cType: "",
      */

      // campaign_id: "",
      // campaign: null,

      // modals
      showLoadingModal: false,
      modal: {
        type: "",
        name: "",
        desc: "",
        show: false,
        callback: null,
        cancelCallback: null,
      },
    };
  }

  /* MODALS */
  showModal = (type, title, desc, callback, cancelCallback) => {
    this.setState({
      modal: {
        type,
        name: title,
        desc,
        show: true,
        callback,
        cancelCallback,
      },
      showLoadingModal: false,
    });
  };

  hideModal = () => {
    this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        show: false,
      },
      showLoadingModal: false,
    }));
  };

  toggleLoading = (status) => {
    this.setState({ showLoadingModal: status });
  };
  /* END MODALS */

  checkRedirection = () => {
    const redir = localStorage.getItem("redir");
    if (redir) {
      const requestedUrl = new URL(window.location.href);
      localStorage.removeItem("redir");
      redirectTo('https://', requestedUrl.hostname, redir)
    }
  };

  componentDidMount() {
    this.getProfile();
  }

  async getProfile() {
    const response = await axios
      .get(`${properties.host}users/me`, {
        headers: {
          Authorization: getCookie("admin_mot_at"),
          ...(properties.env === "LOCAL" && {
            "x-api-key": properties.apiKeyHeader,
          }),
        },
      })
      .catch((e) => {
        error(e);
        this.showModal(
          "error",
          "Error",
          "No se pudo obtener el perfil de usuario",
          () =>
            this.showModal(
              "info",
              "Se requiere nuevo Login",
              "",
              redirectToLogin
            )
        );
      });

    if (response) {
      const { data } = response;
      this.setState({
        email: data.email,
        roles: data.roles.length
          ? data.roles.split(",").map((item) => item.trim())
          : [],
      });

      this.checkRedirection();

    } else {
      this.showModal(
        "error",
        "Error",
        "No se pudo obtener el perfil de usuario",
        () =>
          this.showModal("info", "Se requiere nuevo Login", "", redirectToLogin)
      );
    }
  }

  onChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <>
        <Header
          location={this.props.location.pathname}
          user={this.state.email}
        />

        <div className="main-container">
          <LoadingModal show={this.state.showLoadingModal} />

          <GenericModal
            type={this.state.modal.type}
            name={this.state.modal.name}
            desc={this.state.modal.desc}
            show={this.state.modal.show}
            callback={() => {
              this.hideModal();
              if (this.state.modal.callback) {
                this.state.modal.callback();
              }
            }}
            cancelCallback={this.state.modal.cancelCallback}
          />

          <Route exact path="/admin">
            <CampaignList
              roles={this.state.roles}
              showModal={this.showModal}
              hideModal={this.hideModal}
              toggleLoading={this.toggleLoading}
            />
          </Route>

          <Route path="/admin/campaigndetail/:id">
            {/*<CampaignDetail
              onChange={this.onChange}
              showModal={this.showModal}
              hideModal={this.hideModal}
              toggleLoading={this.toggleLoading}
            />*/}
            <CampaignDetail
              showModal={this.showModal}
              hideModal={this.hideModal}
              toggleLoading={this.toggleLoading}
            />
          </Route>

          <Route path="/admin/createcampaigns/:type">
            {/*<CampaignCreation
              data={this.state}
              creator={this.state.email}
              onChange={this.onChange}
              onServiceDataChange={this.onServiceDataChange}
              onSubmit={this.onSubmit}
              showModal={this.showModal}
              hideModal={this.hideModal}
              toggleLoading={this.toggleLoading}
            />*/}
            <CampaignCreation
              showModal={this.showModal}
              hideModal={this.hideModal}
              toggleLoading={this.toggleLoading}
            />
          </Route>

          <Route exact path="/admin/error">
            <Error redirect="" />
          </Route>
        </div>
      </>
    );
  }
}

export default withRouter(AdminMotivators);
