import { DEPRECATED_PROJECT, PROJECTS, PLANETS } from "../routes/constants";

export const getFriendlyTitle = (name) => {
  return name
    .toUpperCase()
    .replaceAll("_", " ")
    .replaceAll("-", " ")
    .replace("TIT SALIDA", "ASIGNACION")
    .replace("TIT ENTRADA", "DESASIGNACION");
};

export const localDateToISO = (date, time) => {
  const fecha = date;
  const hora = time;
  let iso = "";

  if (hora != null) {
    iso = new Date(
      fecha.getFullYear(),
      fecha.getMonth(),
      fecha.getDate(),
      hora.split(":")[0],
      hora.split(":")[1]
    ).toISOString();
  } else {
    iso = new Date(
      Date.UTC(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), 23, 59)
    ).toISOString();
  }

  return iso;
};

export const formatedDataToServer = (data) => {
  const datos_campania = data;

  let datos = {
    country: datos_campania.campaign_country,
    name: datos_campania.name,
    description: datos_campania.description,
    creator: datos_campania.creator,
    type: datos_campania.type,
    company: datos_campania.company,
    start_date: localDateToISO(datos_campania.start_date, datos_campania.start_time),
    end_date: localDateToISO(datos_campania.end_date, datos_campania.end_time),
    campaign_data: {},
  };

  if (datos.type === 'regular' && datos_campania.remind_date) {
    datos.remind_date = localDateToISO(datos_campania.remind_date, datos_campania.remind_time);
  }

  switch (datos.type) {
    case "regular":
      datos.campaign_data = {
        list: datos_campania.recipients,
      };
      break;
    case "recruiting":
      datos.campaign_data = {
        list: datos_campania.recipients_recruiting,
        position: datos_campania.position,
        project_level_1: datos_campania.project_level_1,
        project_level_2: datos_campania.project_level_2,
      };
      break;
    default:
      datos.campaign_data = {
        list: datos_campania.recipients,
      };
  }

  return datos;
};

export const getProjectName = (
  project_level_2_id,
  country
) => {
  if (
    project_level_2_id === null ||
    country === null
  ) {
    return null;
  }

  const level2 = parseInt(project_level_2_id);
  let result1 = null;
  let result2 = DEPRECATED_PROJECT;
  
  PROJECTS[country].forEach(p1 => {
    p1.children.forEach(p2 => {
      if (p2.id === level2) {
        result1 = p1;
        result2 = p2.title;
      }
    })
  })

  if (result1 === null) {
    return result2;
  }

  if (result1.title === result2) {
    return result2;
  } else {
    return result1.title + " - " + result2;
  }
};

export const getPlanetName = (planet_id, country) => {
  if (planet_id === null || country === null) {
    return null;
  }
  const planet = parseInt(planet_id);
  const result = PLANETS[country].find((p) => p.id === planet);
  return result.title;
};
