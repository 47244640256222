import React from "react";
import Navigation from "../../components/Navigation";
import { strings } from "../strings";

export default function () {
  return (
    <div className="text-center">
      <p className="mb-5">
        <span className="icon-cross text-error f-s-100" />
      </p>
      <p className="mb-3 f-s-20">{strings.errors.formSendingUnexpected}</p>
      <p className="mb-5 f-s-20">{strings.errors.pleaseTryAgain}</p>
      <Navigation back="/admin" />
    </div>
  );
}
