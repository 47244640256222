import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import getLocationDescriptor from "../../helpers/get-location-descriptor";
import { destroyCookieAndRedirect } from "../../helpers/cookies-manager";

export default function ({ location, user }) {
  const rootpath = location.substr(1).split("/")[0];
  const userName = !!user ? user.substr(0, user.indexOf("next") - 1) : "";

  return (
    <header className="App-header">
      <div className="main-container">
        <h1 className="">
          <Link to={getLocationDescriptor(`/${rootpath}`)}>
            <img src={logo} className="App-logo" alt="logo" />
          </Link>
          <span>Motivators Manager</span>
          <p className="logout">
            <span className="user-mail">{userName}</span>
            <i
              className="icon-exit"
              onClick={() => destroyCookieAndRedirect("admin_mot_at")}
            />
          </p>
        </h1>
      </div>
    </header>
  );
}
