import React, { Component } from "react";
import { LineChart, XAxis, YAxis, Line, ReferenceLine } from "recharts";

export default class DISCChart extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.state.data = Object.keys(props.data).map((c) => {
      return { name: c, value: props.data[c].mean.toFixed(1) };
    });

    this.state.width = this.props.width || 250;
    this.state.height = this.props.height || 200;
  }

  render() {
    return (
      <div className="disc-chart">
        <LineChart
          width={this.state.width}
          height={this.state.width}
          data={this.state.data}
          margin={{
            top: 10,
            right: 10,
            left: 10,
            bottom: 10,
          }}
        >
          <XAxis
            dataKey="name"
            padding={{ left: 20, right: 20 }}
            axisLine={false}
            tickLine={false}
          />
          <YAxis domain={[0, 100]} padding={{ bottom: 20, top: 20 }} hide />
          <ReferenceLine y={50} stroke="grey" />
          <Line
            type="linear"
            dataKey="value"
            stroke="#072146"
            strokeWidth={3}
            dot={{ r: 5 }}
          />
        </LineChart>
      </div>
    );
  }
}
