import React, { Component } from "react";
import { ComposedChart, Bar, XAxis, Cell, LabelList } from "recharts";

export default class CultureChart extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.state.data = Object.keys(props.data)
      .map((c) => {
        return { name: c, value: props.data[c].toFixed(1) };
      })
      .filter((i) => i.value > 0)
      .sort((a, b) => (a.value > b.value ? -1 : 1));

    this.state.width = this.props.width || 500;
    this.state.height = this.props.height || 220;
  }

  render() {
    return (
      <div className="culture-chart">
        <ComposedChart
          width={this.state.width}
          height={this.state.height}
          layout="vertical"
          data={this.state.data}
          margin={{
            top: 10,
            right: 80,
            bottom: 10,
            left: 10,
          }}
        >
          <XAxis type="number" domain={[0, 100]} hide />
          <Bar dataKey="value" barSize={20}>
            <LabelList dataKey="value" position="insideRight" fill="white" />
            <LabelList dataKey="name" position="right" />
            {this.state.data.map((entry, index) => (
              <Cell
                key={index}
                fill={
                  entry.value === this.state.data[0].value
                    ? "#5AC4C4"
                    : "#072146"
                }
              />
            ))}
          </Bar>
        </ComposedChart>
      </div>
    );
  }
}
