import React, { Component } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default class PrioritiesChart extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.state.intrinsic_vs_extrinsic = [
      {
        name: "Intrínsecos vs. Extrínsecos",
        Intrínsecos:
          (100 * props.data["Prioridad Intrínsecos"]) /
          (
            props.data["Prioridad Intrínsecos"] +
            props.data["Prioridad Extrínsecos"]
          ).toFixed(1),
        Extrínsecos:
          (100 * props.data["Prioridad Extrínsecos"]) /
          (
            props.data["Prioridad Intrínsecos"] +
            props.data["Prioridad Extrínsecos"]
          ).toFixed(1),
      },
    ];

    this.state.model_indexes = [
      {
        name: "Índices del modelo",
        Meaning:
          (100 * props.data["Prioridad Meaning Index"]) /
          (
            props.data["Prioridad Meaning Index"] +
            props.data["Prioridad Fit Index"] +
            props.data["Prioridad Progress Index"]
          ).toFixed(1),
        Progress:
          (100 * props.data["Prioridad Progress Index"]) /
          (
            props.data["Prioridad Meaning Index"] +
            props.data["Prioridad Fit Index"] +
            props.data["Prioridad Progress Index"]
          ).toFixed(1),
        Fit:
          (100 * props.data["Prioridad Fit Index"]) /
          (
            props.data["Prioridad Meaning Index"] +
            props.data["Prioridad Fit Index"] +
            props.data["Prioridad Progress Index"]
          ).toFixed(1),
      },
    ];

    this.state.width = this.props.width || 800;
    this.state.height = this.props.height || 200;
  }

  render() {
    return (
      <div className="priorities-chart">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <ResponsiveContainer height={this.state.height}>
              <BarChart
                data={this.state.intrinsic_vs_extrinsic}
                margin={{
                  top: 20,
                  right: 10,
                  bottom: 10,
                  left: 10,
                }}
              >
                <YAxis domain={[0, "dataMax"]} hide />
                <XAxis dataKey="name" hide />
                <Tooltip
                  formatter={(value, name, props) => [
                    value.toFixed(1) + "%",
                    name,
                    props,
                  ]}
                />
                <Bar dataKey="Intrínsecos" barSize={20} fill="#5AC4C4" />
                <Bar dataKey="Extrínsecos" barSize={20} fill="#072146" />
                <Legend verticalAlign="bottom"></Legend>
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="col-xs-12 col-sm-6">
            <ResponsiveContainer height={this.state.height}>
              <BarChart
                data={this.state.model_indexes}
                margin={{
                  top: 20,
                  right: 10,
                  bottom: 10,
                  left: 10,
                }}
              >
                <YAxis domain={[0, "dataMax"]} hide />
                <XAxis dataKey="name" hide />
                <Tooltip
                  formatter={(value, name, props) => [
                    value.toFixed(1) + "%",
                    name,
                    props,
                  ]}
                />
                <Bar dataKey="Meaning" barSize={20} fill="#5AC4C4" />
                <Bar dataKey="Progress" barSize={20} fill="#072146" />
                <Bar dataKey="Fit" barSize={20} fill="#C569B9" />
                <Legend verticalAlign="bottom"></Legend>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }
}
