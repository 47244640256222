import React, { Component } from "react";
import { withRouter } from "react-router";
import Navigation from "../../components/Navigation";
import CultureChart from "../../components/CultureChart";
import DISCChart from "../../components/DISCChart";
import PrioritiesChart from "../../components/PrioritiesChart";
import ModelMetricsChart from "../../components/ModelMetricsChart";
import ParticipationTable from "../../components/ParticipationTable";
import axios from "axios";
import { properties } from "../../properties.js";
import { getCookie, redirectToLogin } from "../../helpers/cookies-manager";
import {
  getFriendlyTitle,
  getPlanetName,
  getProjectName,
} from "../../helpers/converters";
import classnames from "classnames";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import {
  DISCModal,
  CulturesModal,
  ModelIndexesModal,
} from "../../components/Modals";
import {
  CAMPAIGN_TYPES_FRIENDLY_NAMES,
  CULTURE_DESCRIPTIONS,
  DISC_DESCRIPTIONS,
  MODEL_INDEXES_DESCRIPTIONS
} from "../constants";
import { strings } from "../strings";

const { error } = console;

class CampaignDetail extends Component {
  constructor(props) {
    super(props);

    const id = this.props.match.params.id; // requires withRouter
    const authToken = getCookie("admin_mot_at");

    this.state = {
      activeTab: 0,
      homePath: "/admin",
      showLoadingModal: false,
      campaignId: id,
      campaignDetail: null,
      authToken,
      showCulturesModal: false,
      showDISCModal: false,
      showModelIndexesModal: false,
    };
  }

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setActiveTab(tab);
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getCampaignDetail(this.state.campaignId);
  }

  async getCampaignDetail(id) {
    this.props.toggleLoading(true);
    const campaignDetail = await axios
      .get(`${properties.host}campaigns/${id}/resume`, {
        headers: {
          Authorization: this.state.authToken,
          ...(properties.env === "LOCAL" && {
            "x-api-key": properties.apiKeyHeader,
          }),
        },
      })
      .catch((e) => {
        error(e);
        this.props.showModal(
          "error",
          strings.modal.error.title,
          strings.errors.campaignDetail
        );
      });

    if (campaignDetail) {
      /* if (
        campaignDetail.data.campaign.type === "salida" &&
        (!campaignDetail.data.items || campaignDetail.data.items.length === 0)
      ) {
        campaignDetail.data.items.push({});
      } */

      this.setState({ campaignDetail: campaignDetail.data });
    } else {
      this.props.showModal(
        "error",
        strings.modal.error.title,
        strings.errors.campaignDetail,
        redirectToLogin
      );
    }

    this.props.toggleLoading(false);
  }

  setNavigation() {
    return <Navigation back={this.state.homePath} />;
  }

  render() {
    return (
      this.state.campaignDetail && (
        <div className="campaign-detail">
          <div className="row">
            <div className="col-xs-12 col-sm-8">
              <h3 className="mb-3">
                {getFriendlyTitle(this.state.campaignDetail.campaign.name)}
                <span className="subtitle ml-1">
                  {this.state.campaignDetail.recruiting &&
                  "recruiting" === this.state.campaignDetail.campaign.type
                    ? this.state.campaignDetail.recruiting.position
                    : ""}

                  {"tit_vacante_solicitada" ===
                    this.state.campaignDetail.campaign.type ||
                  "tit_vacante_conseguida" ===
                    this.state.campaignDetail.campaign.type
                    ? this.state.campaignDetail.campaign.vacancy.name
                    : ""}
                </span>
                <span
                  className={
                    "ml-1 badge badge-campaign-type badge-" +
                    this.state.campaignDetail.campaign.type
                  }
                >
                  {
                    CAMPAIGN_TYPES_FRIENDLY_NAMES[
                      this.state.campaignDetail.campaign.type
                    ]
                  }
                </span>
                <p>
                  {this.state.campaignDetail.recruiting &&
                  "recruiting" === this.state.campaignDetail.campaign.type &&
                  this.state.campaignDetail.recruiting.project_level_1 &&
                  this.state.campaignDetail.recruiting.project_level_2
                    ? getProjectName(
                        this.state.campaignDetail.recruiting.project_level_2,
                        this.state.campaignDetail.campaign.country
                      )
                    : ""}
                </p>
              </h3>
            </div>

            <div className="col-xs-12 col-sm-4">
              <div className="float-right">
                {this.state.campaignDetail.participation && (
                  <p className="pt-1 pb-1">
                    <span className="text-bold">
                      {strings.campaignDetail.participation}&nbsp;
                      {(
                        (100 * this.state.campaignDetail.participation.count) /
                        this.state.campaignDetail.participation.total
                      ).toFixed(1)}
                      %&nbsp;
                    </span>
                    (
                    {this.state.campaignDetail.participation.count +
                      " " +
                      strings.shared.of +
                      " " +
                      this.state.campaignDetail.participation.total}
                    )
                  </p>
                )}
              </div>
            </div>
          </div>

          <Nav tabs className="nav-justified">
            {this.state.campaignDetail.campaign.type !== "salida" &&
              this.state.campaignDetail.items.map((p, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === index,
                    })}
                    onClick={() => this.toggleTab(index)}
                  >
                    <h4>{p["name"]}</h4>
                  </NavLink>
                </NavItem>
              ))}
          </Nav>

          <TabContent activeTab={this.state.activeTab}>
            {this.state.campaignDetail.campaign.type === "salida" &&
              (this.state.campaignDetail.items.length === 0 ||
                Object.keys(this.state.campaignDetail.items[0]).length ===
                  0) && (
                <p className="text-center text-bold mt-2 mb-2">
                  <i className="icon-info mr-1" />
                  {strings.campaignDetail.pendingEmployeeResponse}
                </p>
              )}

            {this.state.campaignDetail.campaign.type === "recruiting" &&
              this.state.campaignDetail.items.length === 0 && (
                <p className="text-center text-bold mt-2 mb-2">
                  <i className="icon-info mr-1" />
                  {strings.campaignDetail.pendingCandidatesResponse}
                </p>
              )}

            {this.state.campaignDetail.campaign.type === "regular" && (
              <ParticipationTable
                data={this.state.campaignDetail.participation}
                country={this.state.campaignDetail.campaign.country}
              />
            )}

            {this.state.campaignDetail.items.map((p, index) => (
              <TabPane tabId={index} key={index}>
                <div className="pr-3 pl-3 pb-3">
                  <div className="text-center">
                    {this.state.campaignDetail.recruiting &&
                      "recruiting" ===
                        this.state.campaignDetail.campaign.type &&
                      p.planet &&
                      p.level && (
                        <>
                          <h5 className="pt-1">
                            {getPlanetName(
                              p.planet,
                              this.state.campaignDetail.campaign.country
                            ) +
                              " (nivel " +
                              p.level +
                              ")"}
                            <span className="ml-1 badge badge-info">
                              {parseInt(p.internalization) === 1
                                ? "Internalización"
                                : ""}
                            </span>
                          </h5>
                        </>
                      )}
                  </div>
                  <div className="row">
                    {this.state.campaignDetail.campaign.type !==
                      "recruiting" && (
                      <div className="col-xs-12 col-sm-6">
                        {p.metrics && (
                          <>
                            <ModelIndexesModal
                              show={this.state.showModelIndexesModal}
                              handleClose={() =>
                                this.setState({ showModelIndexesModal: false })
                              }
                              infoMap={MODEL_INDEXES_DESCRIPTIONS}
                            />
                            <p className="text-bold pt-3 pb-1">
                              {strings.campaignDetail.modelIndicators}&nbsp;
                              <i
                                className="icon-info mr-1 pointer"
                                onClick={() => {
                                  this.setState({
                                    showModelIndexesModal: true,
                                  });
                                }}
                              />
                            </p>
                            <ModelMetricsChart data={p.metrics} width={550} />
                          </>
                        )}
                      </div>
                    )}

                    <div
                      className={
                        this.state.campaignDetail.campaign.type !== "recruiting"
                          ? "col-xs-12 col-sm-6"
                          : "col-xs-12"
                      }
                    >
                      {p.priorities && (
                        <>
                          <ModelIndexesModal
                            show={this.state.showModelIndexesModal}
                            handleClose={() =>
                              this.setState({ showModelIndexesModal: false })
                            }
                            infoMap={MODEL_INDEXES_DESCRIPTIONS}
                          />
                          <p className="text-bold pt-3 pb-1">
                            {strings.campaignDetail.priorities}&nbsp;
                            <i
                              className="icon-info mr-1 pointer"
                              onClick={() => {
                                this.setState({
                                  showModelIndexesModal: true,
                                });
                              }}
                            />
                          </p>
                          <PrioritiesChart data={p.priorities} />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12 col-sm-8">
                      {p.culture && (
                        <>
                          <CulturesModal
                            show={this.state.showCulturesModal}
                            handleClose={() =>
                              this.setState({ showCulturesModal: false })
                            }
                            infoMap={CULTURE_DESCRIPTIONS}
                          />
                          <p className="text-bold pt-3 pb-1">
                            {strings.campaignDetail.culturalAffinity}&nbsp;
                            <i
                              className="icon-info mr-1 pointer"
                              onClick={() => {
                                this.setState({ showCulturesModal: true });
                              }}
                            />
                          </p>
                          <CultureChart data={p.culture} width={550} />
                        </>
                      )}
                    </div>

                    <div className="col-xs-12 col-sm-4">
                      {p.disc && (
                        <>
                          <DISCModal
                            show={this.state.showDISCModal}
                            handleClose={() =>
                              this.setState({ showDISCModal: false })
                            }
                            infoMap={DISC_DESCRIPTIONS}
                          />
                          <p className="text-bold pt-3 pb-1">
                            {strings.campaignDetail.personalProfile}&nbsp;
                            <i
                              className="icon-info mr-1 pointer"
                              onClick={() => {
                                this.setState({ showDISCModal: true });
                              }}
                            />
                          </p>
                          <DISCChart data={p.disc} />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </TabPane>
            ))}
          </TabContent>

          {this.setNavigation()}
        </div>
      )
    );
  }
}

export default withRouter(CampaignDetail);
