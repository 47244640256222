import { properties } from "../properties";

export const parseJWT = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const setCookie = (name, value, days) => {
  let expires = "";

  if (days) { // parseJWT.exp gives us the expiration date
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }

  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name) => {
  let nameEQ = name + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }

  return null;
};

export const redirectToLogin = () => {
  window.location.href = properties.loginURL;
};

export const redirectTo = (protocol, host, path) => {
  window.location.href = protocol + host + path;
};

export const destroyCookieAndRedirect = (name) => {
  setCookie(name, "", -1);
  window.location.href = properties.loginURL;
};

export const destroyCookieAndCloseModal = (name, onChange) => {
  setCookie(name, "", -1);
  onChange("hasError", false);
  window.location.href = properties.loginURL;
};
