import React, { Component } from "react";
import { withRouter } from "react-router";
import Navigation from "../../components/Navigation";
import FileUploader from "../../components/FileUploader";
import SelectOption from "../../components/SelectOption";
import moment from "moment";
import {
  getCookie,
  parseJWT,
  redirectToLogin,
} from "../../helpers/cookies-manager";
import { formatedDataToServer, getPlanetName } from "../../helpers/converters";
// https://reactjsexample.com/a-simple-calendar-component-for-react-based-applications/
// https://reactjsexample.com/google-keep-app-inspired-time-picker-for-react/
import SimpleReactCalendar from "simple-react-calendar";
import Timekeeper from "react-timekeeper";
import axios from "axios";
import { strings } from "../strings";

import {
  COUNTRY,
  DAYSOFWEEK,
  COMPANY,
  CAMPAIGN_TYPES_FRIENDLY_NAMES,
  PROJECTS,
  PLANETS,
  LEVELS,
  YESNO,
} from "../constants";

import { properties } from "../../properties";
const { error } = console;

class CampaignCreation extends Component {

  constructor(props) {
    super(props);

    const ctype = this.props.match.params.type; // requires withRouter
    this.default_reminder_hour = "12:00"
    const now = new Date();
    now.setMinutes(now.getMinutes() + 20); // 20 minutes default delay
    const cur_hour = this.padTo2Digits(now.getHours()) + ':' + this.padTo2Digits(now.getMinutes());

    this.state = {
      //the_time: this.props.data.current_hour,
      showTime: false,
      showTimeRemind: false,
      showTimeEnd: false,
      template: null,
      homePath: "/admin",
      recipients_recruiting: [
        {
          name: "",
          email: "",
          planet: null,
          level: null,
          internalization: null,
        },
      ],
      recipients: [],
      creator: null,
      campaign_country: COUNTRY.length === 1 ? "ES" : false,
      name: null,
      description: "",
      type: ctype,
      company: COMPANY.length === 1 ? "bbva_next" : null,
      start_date: null,
      end_date: null,
      remind_date: null,
      start_time: cur_hour,
      end_time: cur_hour,
      remind_time: this.default_reminder_hour,
      context: null,
      vacancyId: "",
      vacancyName: "",
    };
  }

  padTo2Digits(num) {
    return String(num).padStart(2, '0');
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setCampaignCreator();
  }

  setCampaignCreator() {
    const id_token = getCookie("admin_mot_at");
    const authInfo = parseJWT(id_token);

    if (authInfo === null) {
      this.props.showModal(
        "error",
        strings.showModal.error.title,
        strings.errors.userProfile,
        () =>
          this.props.showModal(
            "info",
            strings.errors.loginRequired,
            "",
            redirectToLogin
          )
      );
    } else {
      this.setState({ creator: authInfo.email });
    }
  }

  setTemplate() {
    let template = null;

    switch (this.state.type) {
      case "regular":
        template = this.renderNext();
        break;
      case "recruiting":
        template = this.renderRecruiting();
        break;
      default:
        template = (
          <div>
            {this.renderCommonRecipients()}
            {this.renderScheduleButton()}
          </div>
        );
        break;
    }

    return template;
  }

  setNavigation() {
    return <Navigation back={this.state.homePath} />;
  }

  changeValue = (event) => {
    const { name, value } = event.target;

    if (this.state.type === "recruiting" && name === "project_level_1") {
      const subareaProjects = this.getSubareaProjects(value);
      this.setState({
        project_level_2:
          subareaProjects.length === 1
            ? subareaProjects[0].id.toString()
            : null,
      });
    }

    this.setState({ [name]: value });
  };

  changeRecipientsByFile = (data) => {
    this.setState({ recipients: data });
  };

  getSubareaProjects = (value) => {
    if (!value) {
      return null;
    }
    const level1 = parseInt(value);
    const result = PROJECTS[this.state.campaign_country].find(
      (p) => p.id === level1
    );
    return result.children;
  };

  onRecruitingRecipientChange = (event, index) => {
    const { name, value } = event.target;
    const list = [...this.state.recipients_recruiting];
    list[index][name] = value;
    this.setState({ recipients_recruiting: list });
    //this.props.onServiceDataChange("list", list);
  };

  addRecruitingRecipient = () => {
    var new_candidate = {
      name: "",
      email: "",
      planet: null,
      level: null,
      internalization: null,
    };
    this.setState((prevState) => ({
      recipients_recruiting: prevState.recipients_recruiting.concat([
        new_candidate,
      ]),
    }));
    /*this.props.onServiceDataChange(
      "list",
      this.state.recipients_recruiting.concat([new_candidate])
    );*/
  };

  removeRecruitingRecipient = (index) => {
    const list = [...this.state.recipients_recruiting];
    list.splice(index, 1);
    this.setState({ recipients_recruiting: list });
    // this.props.onServiceDataChange("list", list);
  };

  onRecipientChange = (event, index) => {
    const { value } = event.target;
    const list = [...this.state.recipients];
    list[index] = value;
    this.setState({ recipients: list });
  };

  onRecipientVacancyChange = (event) => {
    const { value } = event.target;
    const list = [];
    list.push(value);
    this.setState({ recipients: list });
  };

  addRecipient = () => {
    var new_candidate = "";
    this.setState((prevState) => ({
      recipients: prevState.recipients.concat([new_candidate]),
    }));
  };

  removeRecipient = (index) => {
    const list = [...this.state.recipients];
    list.splice(index, 1);
    this.setState({ recipients: list });
  };

  isValidEmail = (e) => {
    // eslint-disable-next-line
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(e);
  };

  manageDate = (event) => {
    const inicio = event.start;
    const fin = event.end;

    this.setState({ start_date: inicio });
    this.setState({ end_date: fin });
  };

  manageTime = (newTime) => {
    this.setState({ start_time: newTime.formatted24 });
  };

  manageTimeEnd = (newTime) => {
    this.setState({ end_time: newTime.formatted24 });
  };

  manageTimeRemind = (newTime) => {
    this.setState({ remind_time: newTime.formatted24 });
  };

  manageOutDate = (event) => {
    this.setState({ leaving_date: event });
  };

  manageRemindDate = (event) => {
    this.setState({ remind_date: event });
  };

  checkLaunchTime = () => {
    const datenow = new Date();
    const launchDate = this.state.start_date;
    let result = false;

    if (this.state.start_date && this.state.start_time) {
      const startTimeParts = this.state.start_time.split(":");
      const launchHours = parseInt(startTimeParts[0]);
      const launchMinutes = parseInt(startTimeParts[1]);
      launchDate.setHours(launchHours);
      launchDate.setMinutes(launchMinutes);
      const diff = moment(launchDate).diff(moment(datenow), "minutes");
      result = diff >= 5;

      if (!result) {
        this.setState({ start_time: "23:59" });
        this.props.showModal(
          "error",
          strings.errors.startTime.title,
          strings.errors.startTime.description
        );
      }
    }

    return result;
  };

  validaDatos() {
    let errores = 0;

    if (
      this.state.company === null ||
      this.state.campaign_country === null ||
      this.state.name === null ||
      this.state.name.trim().length === 0 ||
      this.state.start_date === null ||
      this.state.end_date === null
    ) {
      errores++;
    }

    if (!this.checkLaunchTime()) {
      errores++;
    }

    switch (this.state.type) {
      case "recruiting":
        if (
          this.state.position === undefined ||
          this.state.position === null ||
          this.state.position.trim().length === 0 ||
          this.state.project_level_1 === null ||
          this.state.project_level_2 === null
        ) {
          errores++;
        }
        break;
      case "regular":
        if (
          this.state.start_date < this.state.end_date &&
          this.state.remind_date === null
        ) {
          errores++;
        }
        break;
      default:
    }

    if (
      this.state.type === "recruiting" &&
      this.state.recipients_recruiting.length > 0
    ) {
      this.state.recipients_recruiting.map((candidate) => {
        if (
          candidate.name.trim().length === 0 ||
          candidate.email.trim().length === 0 ||
          !this.isRecruitingCandidateComplete(candidate)
        ) {
          // this.props.showModal("error", "Error", "Algún email no es válido");
          errores++;
        }
        return false;
      });
    }

    if (
      this.state.type !== "salida" &&
      this.state.type !== "recruiting" &&
      (this.state.recipients === undefined ||
        this.state.recipients.length === 0)
    ) {
      errores++;
    }

    if (this.state.type !== "recruiting" && this.state.recipients.length > 0) {
      this.state.recipients.map((recipient) => {
        if (recipient.length === 0 || !this.isValidEmail(recipient)) {
          // this.props.showModal("error", "Error", "Algún email no es válido");
          errores++;
        }
        return false;
      });
    }

    return errores === 0 ? true : false;
  }

  renderNewCampaignData() {
    const today = new Date();
    const yesterday = today.setDate(today.getDate() - 1);

    return (
      <div className="new-campaign">
        {COMPANY.length > 1 && (
          <div className="select-container">
            <div className="select-item">
              <SelectOption
                label={strings.campaignCreation.form.company}
                name="company"
                onChange={this.changeValue}
                options={COMPANY}
                defaultValue={this.state.company}
              />
            </div>
          </div>
        )}

        {COUNTRY.length > 1 && (
          <div className="select-container">
            <div className="select-item">
              <SelectOption
                label={strings.campaignCreation.form.country}
                name="campaign_country"
                onChange={this.changeValue}
                options={COUNTRY}
                defaultValue={this.state.campaign_country}
              />
            </div>
          </div>
        )}

        <div className="select-container">
          <div className="select-item">
            <label htmlFor="campaignName">
              {strings.campaignCreation.form.name}
            </label>
            <input
              type="text"
              className="select-item"
              placeholder=""
              name="name"
              defaultValue={this.state.name}
              onChange={this.changeValue}
            />
          </div>
        </div>

        <div className="select-container">
          <div className="select-item">
            <label htmlFor="campaignDesc">
              {strings.campaignCreation.form.description}
            </label>
            <input
              type="text"
              className="select-item"
              placeholder=""
              name="description"
              defaultValue={this.state.description}
              onChange={this.changeValue}
            />
          </div>
        </div>

        <div className="select-container">
          <div className="select-item">
            <label htmlFor="fecha">
              <span>{strings.campaignCreation.form.dates}</span>
              {this.state.type === "recruiting" && (
                <span
                  className="hint block"
                  dangerouslySetInnerHTML={{
                    __html: strings.campaignCreation.form.datesRecruitingHint,
                  }}
                />
              )}
            </label>

            <SimpleReactCalendar
              name="fecha"
              daysOfWeek={DAYSOFWEEK}
              minDate={yesterday}
              mode="range"
              weekStartsOn="1"
              onSelect={this.manageDate}
              selected={{
                start: this.state.start_date,
                end: this.state.end_date,
              }}
            />
          </div>
        </div>

        <div className="select-container">
          <div className="select-item">
            <label htmlFor="hora">
              {strings.campaignCreation.form.startTime}
            </label>
            {this.state.showTime ? (
              <div className="time-selector">
                <Timekeeper
                  name="hora"
                  time={this.state.start_time}
                  hour24Mode
                  switchToMinuteOnHourSelect
                  onChange={this.manageTime}
                  onDoneClick={() =>
                    this.setState({ showTime: !this.state.showTime })
                  }
                />
              </div>
            ) : (
              <button
                className="btn-time-selector"
                onClick={() =>
                  this.setState({ showTime: !this.state.showTime })
                }
              >
                <span className="icon-clock" />
                {this.state.start_time !== ""
                  ? this.state.start_time
                  : strings.campaignCreation.form.selectTime}
              </button>
            )}
          </div>
        </div>

        <div className="select-container">
          <div className="select-item">
            <label htmlFor="hora_end">
              {strings.campaignCreation.form.endTime}
            </label>
            {this.state.showTimeEnd ? (
              <div className="time-selector">
                <Timekeeper
                  name="hora_end"
                  time={this.state.end_time}
                  hour24Mode
                  switchToMinuteOnHourSelect
                  onChange={this.manageTimeEnd}
                  onDoneClick={() =>
                    this.setState({ showTimeEnd: !this.state.showTimeEnd })
                  }
                />
              </div>
            ) : (
              <button
                className="btn-time-selector"
                onClick={() =>
                  this.setState({ showTimeEnd: !this.state.showTimeEnd })
                }
              >
                <span className="icon-clock" />
                {this.state.end_time !== ""
                  ? this.state.end_time
                  : strings.campaignCreation.form.selectTime}
              </button>
            )}
          </div>
        </div>

        {/* reminder */}
        {this.state.type === "regular" &&
          !!this.state.start_date &&
          !!this.state.end_date &&
          this.state.start_date < this.state.end_date && (
            <div>
              <div className="select-container">
                <div className="select-item">
                  <label htmlFor="fecha">
                    <span>
                      {strings.campaignCreation.form.employeeReminderDate}
                    </span>
                    <span className="hint block">
                      {strings.campaignCreation.form.employeeReminderDateHint}
                    </span>
                  </label>
                  <SimpleReactCalendar
                    name="remind_date"
                    daysOfWeek={DAYSOFWEEK}
                    minDate={this.state.start_date}
                    maxDate={this.state.end_date}
                    weekStartsOn="1"
                    onSelect={this.manageRemindDate}
                    selected={this.state.remind_date}
                  />
                </div>
              </div>

              <div className="select-container">
                <div className="select-item">
                  <label htmlFor="hora">
                    {strings.campaignCreation.form.remindTime}
                  </label>
                  {this.state.showTimeRemind ? (
                    <div className="time-selector">
                      <Timekeeper
                        name="hora"
                        time={this.state.remind_time}
                        hour24Mode
                        switchToMinuteOnHourSelect
                        onChange={this.manageTimeReminder}
                        onDoneClick={() =>
                          this.setState({ showTimeRemind: !this.state.showTimeRemind })
                        }
                      />
                    </div>
                  ) : (
                    <button
                      className="btn-time-selector"
                      onClick={() =>
                        this.setState({ showTimeRemind: !this.state.showTimeRemind })
                      }
                    >
                      <span className="icon-clock" />
                      {this.state.remind_time !== ""
                        ? this.state.remind_time
                        : strings.campaignCreation.form.selectTime}
                    </button>
                  )}
                </div>
              </div>
            </div>
        )}
      </div>
    );
  }

  renderNext() {
    return (
      <div className="new-campaign">
        {this.renderCommonRecipients()}
        {this.renderScheduleButton()}
      </div>
    );
  }

  renderRecruiting() {
    return (
      <div className="new-campaign talent-magnet-container">
        <div className="mb-4">
          <div className="select-container">
            <div className="select-item">
              <label>{strings.campaignCreation.form.position}</label>
              <input
                type="text"
                className="select-item"
                placeholder={strings.campaignCreation.form.positionPlaceholder}
                name="position"
                defaultValue={this.state.position}
                onChange={this.changeValue}
              />
            </div>
          </div>

          <div className="select-container">
            <div className="select-item">
              <label>{strings.campaignCreation.form.project_level_1}</label>
              <SelectOption
                name="project_level_1"
                onChange={this.changeValue}
                options={PROJECTS[this.state.campaign_country]}
              />
            </div>
          </div>

          {this.state.project_level_1 &&
            this.getSubareaProjects(this.state.project_level_1).length > 1 && (
              <div className="select-container">
                <div className="select-item">
                  <label>{strings.campaignCreation.form.project_level_2}</label>
                  <SelectOption
                    name="project_level_2"
                    onChange={this.changeValue}
                    options={this.getSubareaProjects(
                      this.state.project_level_1
                    )}
                  />
                </div>
              </div>
            )}

          <div className="select-container">
            <div className="select-item">
              <label>{strings.campaignCreation.form.candidateList}</label>
            </div>
          </div>

          {this.state.recipients_recruiting.map((candidate, index) => {
            return (
              <div className="mb-2 ml-1" key={index}>
                <div className="row">
                  <div className="hidden-xs col-sm-4"></div>
                  <div className="col-xs-12 col-sm-8 recruiting-recipient-container">
                    <div className="row mt-2 mb-1">
                      <div className="col-xs-12">
                        <h4>
                          {strings.campaignCreation.form.candidate_item_title}{" "}
                          {index + 1}:&nbsp;
                          <span className="text-regular">{candidate.name}</span>
                        </h4>
                      </div>
                    </div>

                    {!this.isRecruitingCandidateComplete(candidate) && (
                      <>
                        <div className="row mt-1 mb-2">
                          <div className="col-xs-12 col-sm-6">
                            <input
                              type="text"
                              className="select-item"
                              placeholder={
                                strings.campaignCreation.form
                                  .candidateNamePlaceholder
                              }
                              name="name"
                              value={candidate.name}
                              onChange={(event) =>
                                this.onRecruitingRecipientChange(event, index)
                              }
                            />
                          </div>

                          <div className="col-xs-12 col-sm-6">
                            <input
                              type="text"
                              className="select-item"
                              placeholder={
                                strings.campaignCreation.form
                                  .candidateEmailPlaceholder
                              }
                              name="email"
                              value={candidate.email}
                              onChange={(event) =>
                                this.onRecruitingRecipientChange(event, index)
                              }
                            />
                          </div>
                        </div>

                        <div className="row mt-1 mb-2">
                          <div className="col-xs-12 col-sm-7">
                            <SelectOption
                              label={strings.campaignCreation.form.planet}
                              name="planet"
                              onChange={(event) =>
                                this.onRecruitingRecipientChange(event, index)
                              }
                              options={PLANETS[this.state.campaign_country]}
                            />
                          </div>

                          <div className="col-xs-12 col-sm-2">
                            <SelectOption
                              label={strings.campaignCreation.form.level}
                              name="level"
                              onChange={(event) =>
                                this.onRecruitingRecipientChange(event, index)
                              }
                              options={LEVELS[this.state.campaign_country]}
                            />
                          </div>

                          <div className="col-xs-12 col-sm-3">
                            <SelectOption
                              label={
                                strings.campaignCreation.form.internalization
                              }
                              name="internalization"
                              onChange={(event) =>
                                this.onRecruitingRecipientChange(event, index)
                              }
                              options={YESNO}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {this.isRecruitingCandidateComplete(candidate) && (
                      <div className="candidate-resume text-regular">
                        <p className="pull-right">
                          <span className="text-bold">
                            {candidate.internalization === "1"
                              ? "Internalización"
                              : ""}
                          </span>
                        </p>
                        <p>
                          <span className="text-bold">Planeta:&nbsp;</span>
                          {getPlanetName(
                            candidate.planet,
                            this.state.campaign_country
                          )}{" "}
                          {"nivel " + candidate.level}
                        </p>
                        <p className="text-italic">{candidate.email}</p>
                      </div>
                    )}

                    <div className="row mt-1 mb-2">
                      <div className="col-xs-12 text-center">
                        {this.state.recipients_recruiting.length - 1 ===
                          index && (
                          <button
                            className="btn btn-candidate-add mr-1"
                            onClick={this.addRecruitingRecipient}
                            disabled={
                              !this.isRecruitingCandidateComplete(candidate)
                            }
                          >
                            <i className="icon-Add" />
                          </button>
                        )}

                        {this.state.recipients_recruiting.length !== 1 && (
                          <button
                            className="btn btn-candidate-remove"
                            onClick={() =>
                              this.removeRecruitingRecipient(index)
                            }
                          >
                            <i className="icon-bin" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {this.renderScheduleButton()}
      </div>
    );
  }

  renderCommonRecipients(text = strings.campaignCreation.form.recipients) {
    return (
      <div className="new-campaign">
        <div className="select-container">
          <div className="select-item">
            <label>
              <span>{text}</span>
              <span className="hint block">
                {strings.campaignCreation.form.fileTxtHint}
              </span>
            </label>
            <FileUploader onChange={this.changeRecipientsByFile} />
          </div>
        </div>
      </div>
    );
  }

  isRecruitingCandidateComplete = (candidate) => {
    return !(
      candidate.name.length === 0 ||
      candidate.email.length === 0 ||
      !this.isValidEmail(candidate.email) ||
      candidate.level === null ||
      candidate.planet === null ||
      candidate.internalization === null
    );
  };

  clearCreationForm = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 20); // 20 minutes default delay
    const cur_hour = this.padTo2Digits(now.getHours()) + ':' + this.padTo2Digits(now.getMinutes());

    this.setState({
      recipients_recruiting: [
        {
          name: "",
          email: "",
          planet: null,
          level: null,
          internalization: null,
        },
      ],
      recipients: [],
      creator: null,
      campaign_country: COUNTRY.length === 1 ? "ES" : null,
      name: null,
      description: "",
      type: null,
      company: COMPANY.length === 1 ? "bbva_next" : null,
      start_date: null,
      end_date: null,
      remind_date: null,
      start_time: cur_hour,
      end_time: cur_hour,
      remind_time: this.default_reminder_hour, 
      context: null,
      vacancyId: "",
      vacancyName: "",
    });
  };

  onSubmit = async () => {
    const host = properties.host;
    const data = formatedDataToServer(this.state);

    try {
      this.props.toggleLoading(true);

      const resultCampaign = await axios
        .post(`${host}campaigns`, data, {
          headers: {
            Authorization: getCookie("admin_mot_at"),
            ...(properties.env === "LOCAL" && {
              "x-api-key": properties.apiKeyHeader,
            }),
          },
        })
        .catch((e) => {
          error(e);
          this.props.showModal(
            "error",
            strings.modal.error.title,
            strings.errors.campaignCreation,
            this.redirectToHome
          );
        });

      if (resultCampaign) {
        this.props.showModal(
          "success",
          strings.campaignCreation.campaignCreationSuccess,
          "",
          () => {
            this.redirectToHome();
            this.clearCreationForm();
          }
        );
      }
    } catch (e) {
      error(e);
      this.props.showModal(
        "error",
        strings.modal.error.title,
        strings.errors.campaignCreation
      );
    }
  };

  redirectToHome = () => {
    this.props.history.push(this.state.homePath);
  };

  renderScheduleButton() {
    return (
      <div className="new-campaign">
        {
          (this.state.name && (this.state.start_date === null || this.state.end_date === null)) && 
          <div className="block text-center dates-required">
            <p>
              <i className="icon-warning"/>
              &nbsp;
              <span dangerouslySetInnerHTML={{
                __html: strings.campaignCreation.form.datesRequiredHint
              }}></span>
            </p>
          </div>
        }

        <div className="block text-center">
          {this.validaDatos() && (
            <button onClick={this.onSubmit} className="btn btn-primary">
              {strings.campaignCreation.scheduleCampaign}
            </button>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="create-campaign">
          <div className="mb-2">
            <h2 className="mb-3 text-bold">
              {strings.campaignCreation.new}{" "}
              {CAMPAIGN_TYPES_FRIENDLY_NAMES[this.state.type]}
            </h2>

            <div className="bg-grey text-bold">
              {this.state.type !== null && (
                <div className="data-container">
                  {this.renderNewCampaignData()}
                  {this.setTemplate()}
                </div>
              )}
            </div>
          </div>

          {this.setNavigation()}
        </div>
      </>
    );
  }
}

export default withRouter(CampaignCreation);
