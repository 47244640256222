export const strings = {
  shared: {
    campaign: "Campaña",
    of: "de",
    comments: "Comentarios",
    status: "Estado",
    options: "Opciones",
    type: "Tipo",
    from: "Desde",
    to: "Hasta",
    remove: "Eliminar",
    check: "Consultar",
  },
  campaignCreation: {
    new: "Nueva campaña",
    scheduleCampaign: "Programar Campaña",
    campaignCreationSuccess: "Campaña creada",
    form: {
      company: "Compañía",
      country: "País",
      name: "Nombre",
      description: "Descripción",
      dates: "Fechas de inicio y fin",
      datesRequiredHint:
        "No olvides seleccionar en el calendario la fecha de inicio y la fecha de fin de la encuesta",
      datesRecruitingHint:
        "Si para esta vacante se incluyen varios candidatos finales, todos tendrán los mismos plazos para responderla",
      employeeReminderDate: "Fecha de recordatorio",
      employeeReminderDateHint:
          "Esta fecha será la fecha de envío de email recordatorio de participación",
      startTime: "Hora de comienzo",
      endTime: "Hora de finalización",
      remindTime: "Hora de recordatorio",
      selectTime: "Seleccionar hora",
      recipients: "Destinatarios",
      recipient: "Destinatario",
      emailPlaceholder: "E-mail de empleado",
      candidate_item_title: "Candidato",
      position: "Posición a cubrir",
      positionPlaceholder: "Descripción o identificación de la vacante",
      planet: "Planeta",
      project: "Proyecto",
      project_level_1: "Grupo de proyectos",
      project_level_2: "Subgrupo de proyectos",
      level: "Nivel",
      internalization: "¿Es internalización?",
      candidateList: "Lista de candidatos",
      candidateNamePlaceholder: "Nombre del candidato",
      candidateEmailPlaceholder: "E-mail de candidato",
      fileTxtHint: "Se requiere un fichero .txt con un email por línea",
      vacancyId: "Next Project - Vacante Id",
      vacancyName: "Next Project - Nombre de vacante"
    },
  },
  campaignDetail: {
    priorities: "Prioridades motivaciones",
    mainIndicators: "Indicadores motivacionales",
    personalProfile: "Perfil personal",
    culturalAffinity: "Afinidad cultural",
    participation: "Participación",
    modelIndicators: "Indicadores del modelo"
  },
  campaignList: {
    newCampaign: "Nueva campaña",
    campaignDeletionWarning: "¿Seguro que desea eliminar la campaña?",
    campaignDeletionSuccess: "Campaña eliminada",
    filterDateFormatPlaceholder: "dd/mm/aaaa",
    filterByNameOrDescriptionPlaceholder:
      "Filtrar por nombre o descripción ...",
    hideFilters: "Ocultar filtros",
    advancedFilters: "Filtro avanzado",
    filter: "Filtrar",
    reset: "Restablecer",
    status: {
      created: "Creada",
      finished: "Finalizada el",
      in_progress: "En curso hasta el",
    },
  },
  errors: {
    userProfile: "No se pudo obtener el perfil de usuario",
    loginRequired: "Se requiere nuevo Login",
    startTime: {
      title: "Hora de comienzo demasiado próxima",
      description:
        "La fecha y hora de comienzo ha de establecerse con al menos 5 minutos de margen desde el momento actual",
    },
    campaignCreation: "No se pudo crear la campaña",
    campaignDetail: "No se pudo obtener el detalle de la campaña",
    campaignList: "No se pudo consultar el listado de campañas",
    campaignDeletion: "No se pudo eliminar la campaña",
    campaignListFilters: "Existe algún error en los filtros",
    campaignListEmptyFilters: "No se seleccionó ningún filtro",
    formSendingUnexpected: "Ha ocurrido un error al enviar el formulario.",
    pleaseTryAgain: "Por favor, inténtalo de nuevo.",
    forbidden: "Acceso no permitido",
  },
  modal: {
    error: {
      title: "Error",
    },
    success: {
      title: "",
    },
    warning: {
      title: "Atención",
    },
  },
};
