import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { getCookie, redirectToLogin } from "../../helpers/cookies-manager";
import { properties } from "../../properties.js";
import {
  CAMPAIGN_TYPES_FRIENDLY_NAMES,
  CAMPAIGN_STATUS_FRIENDLY_NAMES,
  ADMIN_ROLE,
  MAIL_DOMAIN,
} from "../constants";
import { getFriendlyTitle } from "../../helpers/converters";
import { strings } from "../strings";

const { error } = console;
// const today = new Date();

class CampaignList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCampaignMenu: false,
      campaigns: [],
      filteredCampaigns: [],
      sel_campaign: "",
      campaign_data: {},
      start_date: "",
      end_date: "",
      leaving_date: "",
      /*the_time:
        (today.getHours() > 9 ? today.getHours() : "0" + today.getHours()) +
        ":" +
        (today.getMinutes() > 9
          ? today.getMinutes()
          : "0" + today.getMinutes()),*/
      showTime: false,
      homePath: "/admin",
      showFilters: false,
      filterTypes: {
        // superado_periodo_prueba: false,
        regular: false,
        // desasignado_entrada: false,
        // desasignado_salida: false,
        // tit_vacante_solicitada: false,
        // tit_vacante_conseguida: false,
        // salida: false,
        // zoom: false,
        recruiting: false,
      },
      filterStatus: {
        created: false,
        scheduled: false,
        // ongoing: false,
        // paused: false,
        closed: false,
        // completed: false,
        // discarded: false,
      },
      filterText: "",
      filterStartDate: "",
      filterEndDate: "",
      errorFechaDesde: true,
      errorFechaHasta: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getCampaigns();
  }

  async getCampaigns() {
    const auth_token = getCookie("admin_mot_at");

    if (!!auth_token) {
      this.props.toggleLoading(true);

      const response = await axios
        .get(properties.host + "campaigns", {
          headers: {
            Authorization: auth_token,
            ...(properties.env === "LOCAL" && {
              "x-api-key": properties.apiKeyHeader,
            }),
          },
        })
        .catch((e) => {
          error(e);
          this.props.showModal(
            "error",
            strings.modal.error.title,
            strings.errors.campaignList,
            redirectToLogin
          );
        });

      const campaigns = [];
      const filteredCampaigns = [];

      if (response) {
        const { data } = response;
        data.map((campaign) => {
          campaigns.push(campaign);
          filteredCampaigns.push(campaign);
          return false;
        });

        this.setState({
          campaigns: campaigns.sort((a, b) => {
            return b.start_date.localeCompare(a.start_date);
          }),
          filteredCampaigns: filteredCampaigns.sort((a, b) => {
            return b.start_date.localeCompare(a.start_date);
          }),
        });
      }

      this.props.toggleLoading(false);
    } else {
      this.props.showModal(
        "info",
        strings.errors.loginRequired,
        "",
        redirectToLogin
      );
    }
  }

  async removeCampaign(campaign) {
    const auth_token = getCookie("admin_mot_at");

    this.props.showModal(
      "warning",
      strings.modal.warning.title,
      strings.campaignList.campaignDeletionWarning,
      () => {
        if (!!auth_token) {
          this.props.toggleLoading(true);

          axios
            .delete(properties.host + `campaigns/${campaign.id}`, {
              headers: {
                Authorization: auth_token,
                ...(properties.env === "LOCAL" && {
                  "x-api-key": properties.apiKeyHeader,
                }),
              },
            })
            .then(() => {
              this.setState({
                campaigns: this.state.campaigns.filter(
                  (c) => c.id !== campaign.id
                ),
                filteredCampaigns: this.state.filteredCampaigns.filter(
                  (c) => c.id !== campaign.id
                ),
              });

              this.props.showModal(
                "success",
                strings.campaignList.campaignDeletionSuccess,
                ""
              );
            })
            .catch((e) => {
              error(e);
              this.props.showModal(
                "error",
                strings.modal.error.title,
                strings.errors.campaignDeletion
              );
              return;
            });
        } else {
          this.props.showModal(
            "info",
            strings.errors.loginRequired,
            "",
            redirectToLogin
          );
        }
      },
      () => {
        this.props.hideModal();
      }
    );
  }

  getCampaignsWithParams(params) {
    let filteredCampaigns = this.state.campaigns;
    let aux = [];

    if (params.type) {
      Object.keys(this.state.filterTypes).forEach((type) => {
        if (this.state.filterTypes[type]) {
          filteredCampaigns.map((campaign) => {
            if (campaign.type === type) {
              aux.push(campaign);
            }
            return false;
          });
        }
      });

      filteredCampaigns = aux;
    }

    if (params.status) {
      Object.keys(this.state.filterStatus).forEach((status) => {
        if (this.state.filterStatus[status]) {
          filteredCampaigns.map((campaign) => {
            if (campaign.status === status) {
              aux.push(campaign);
            }
            return false;
          });
        }
      });

      filteredCampaigns = aux;
    }

    if (params.start_date) {
      let filterDate = new Date(
        this.state.filterStartDate.split("/")[2],
        this.state.filterStartDate.split("/")[1] - 1,
        this.state.filterStartDate.split("/")[0]
      );

      filteredCampaigns.map((campaign) => {
        if (new Date(campaign.start_date) >= filterDate) {
          aux.push(campaign);
        }
        return false;
      });

      filteredCampaigns = aux;
    }

    if (params.end_date) {
      let filterDate = new Date(
        this.state.filterEndDate.split("/")[2],
        this.state.filterEndDate.split("/")[1] - 1,
        this.state.filterEndDate.split("/")[0]
      );

      filteredCampaigns.map((campaign) => {
        if (new Date(campaign.end_date) >= filterDate) {
          aux.push(campaign);
        }
        return false;
      });

      filteredCampaigns = aux;
    }

    this.setState({
      filteredCampaigns,
    });
  }

  handleTypesFilterClick(type) {
    let filterTypes = this.state.filterTypes;
    filterTypes[type] = !this.state.filterTypes[type];
    this.setState({ filterTypes });
  }

  handleStatusFilterClick(status) {
    let filterStatus = this.state.filterStatus;
    filterStatus[status] = !this.state.filterStatus[status];
    this.setState({ filterStatus });
  }

  validarFecha(rawFecha) {
    let fecha = new Date(
      rawFecha.split("/")[2],
      rawFecha.split("/")[1],
      rawFecha.split("/")[0]
    );

    return fecha instanceof Date && !isNaN(fecha);
  }

  applyFilters = () => {
    let params = {
      type: false,
      start_date: false,
      end_date: false,
      status: false,
    };
    let errorFechaDesde = true;
    let errorFechaHasta = true;

    if (Object.values(this.state.filterTypes).includes(true)) {
      params.type = true;
    }

    if (Object.values(this.state.filterStatus).includes(true)) {
      params.status = true;
    }

    if (!!this.state.filterStartDate && this.state.filterStartDate !== "") {
      params.start_date = true;
      if (!this.validarFecha(this.state.filterStartDate)) {
        errorFechaDesde = false;
      }
    }

    if (!!this.state.filterEndDate && this.state.filterEndDate !== "") {
      params.end_date = true;
      if (!this.validarFecha(this.state.filterEndDate)) {
        errorFechaHasta = false;
      }
    }

    this.setState({
      errorFechaDesde,
      errorFechaHasta,
    });

    if (params.start_date || params.end_date || params.type || params.status) {
      if (errorFechaDesde && errorFechaHasta) {
        this.getCampaignsWithParams(params);
      } else {
        this.props.showModal(
          "error",
          strings.modal.error.title,
          strings.errors.campaignListFilters
        );
      }
    } else {
      this.props.showModal("info", strings.errors.campaignListEmptyFilters, "");
    }
  };

  clearFilters = () => {
    this.setState({
      filteredCampaigns: this.state.campaigns,
      filterTypes: {
        // superado_periodo_prueba: false,
        regular: false,
        // desasignado_entrada: false,
        // desasignado_salida: false,
        // tit_vacante_solicitada: false,
        // tit_vacante_conseguida: false,
        // salida: false,
        // zoom: false,
        recruiting: false,
      },
      filterStatus: {
        created: false,
        scheduled: false,
        // ongoing: false,
        // paused: false,
        closed: false,
        // completed: false,
        // discarded: false,
      },
      filterStartDate: "",
      filterEndDate: "",
      errorFechaDesde: true,
      errorFechaHasta: true,
    });
  };

  changeFilterInputValue = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });

    let filteredCampaigns = this.state.campaigns;
    let aux = [];

    if (value.length > 2) {
      let re = new RegExp(value, "i");
      filteredCampaigns.map((campaign) => {
        const cDetails = this.getDetails(campaign);
        if (
          campaign.name.match(re) ||
          campaign.description.match(re) ||
          (cDetails && cDetails.match(re))
        ) {
          aux.push(campaign);
        }
        return false;
      });
      filteredCampaigns = aux;
    }

    this.setState({
      filteredCampaigns,
    });
  };

  changeDate = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  getDetails = (campaign) => {
    if (!campaign.campaign_data || !campaign.campaign_data.list) {
      return;
    } else {
      return campaign.campaign_data.list
        .map((i) => {
          let result = "";
          switch (campaign.type) {
            // case "superado_periodo_prueba":
            // case "zoom":
            // case "salida":
            case "regular":
              result = "HIDDEN";
              break;
            // case "desasignado_entrada":
            // case "desasignado_salida":
            // case "tit_vacante_solicitada":
            /* case "tit_vacante_conseguida":
              result = i;
              break;*/ 
            case "recruiting":
              if (campaign.campaign_data.list.length > 1) {
                result = i.name;
              } else {
                result = "HIDDEN";
              }
              break;
            /*case "salida":
            if (i.role === 'employee'){
              result = i.email;
            } else {
              result = 'HIDDEN'
            }
            break;*/
            default:
              result = "HIDDEN";
              break;
          }
          return result.toLowerCase().replace(MAIL_DOMAIN, "");
        })
        .filter((i) => i !== "HIDDEN".toLowerCase())
        .join(", ");
    }
  };

  render() {
    return (
      <>
        <div className="home">
          <div className="bg-grey">
            <div className="flexcolumn-container">
              <div className="flexrow-container space-between">
                <div className="menu-new-campaign">
                  <div
                    className="btn btn-primary add-campaign"
                    onClick={() =>
                      this.setState({
                        showCampaignMenu: !this.state.showCampaignMenu,
                      })
                    }
                  >
                    <i className="icon-Add"></i>
                    <span>{strings.campaignList.newCampaign}</span>
                  </div>

                  {this.state.showCampaignMenu && (
                    <ul className="create-campaign-by-type-button-list list">
                      {Object.keys(this.state.filterTypes).map(
                        (key, index) =>
                          (this.props.roles.includes(ADMIN_ROLE) ||
                            this.props.roles.includes(key)) && (
                            <li key={index} className="list-item">
                              <Link to={"/admin/createcampaigns/" + key}>
                                <span
                                  className={
                                    "badge badge-campaign-type badge-" + key
                                  }
                                >
                                  {CAMPAIGN_TYPES_FRIENDLY_NAMES[key]}
                                </span>
                              </Link>
                            </li>
                          )
                      )}
                    </ul>
                  )}
                </div>
                
                <div className="flexcolumn-container autocomplete">
                  <div className="input-container">
                    <input
                      type="text"
                      className="nxt-text-input"
                      placeholder={
                        strings.campaignList
                          .filterByNameOrDescriptionPlaceholder
                      }
                      name="filterText"
                      onChange={this.changeFilterInputValue}
                    />
                  </div>
                  <button
                    className="btn btn-primary reduced mt-1"
                    onClick={() =>
                      this.setState({ showFilters: !this.state.showFilters })
                    }
                  >
                    {this.state.showFilters
                      ? strings.campaignList.hideFilters
                      : strings.campaignList.advancedFilters}
                  </button>
                </div>
              </div>

              {this.state.showFilters && (
                <div className="filters-container mt-2">
                  <div className="mt-2 mb-4">
                    <p className="text-bold">Tipo</p>
                    <div className="btns-container mt-2">
                      {Object.keys(this.state.filterTypes).map(
                        (key, index) =>
                          (this.props.roles.includes(ADMIN_ROLE) ||
                            this.props.roles.includes(key)) && (
                            <span
                              key={index}
                              className={
                                "mr-1 pointer badge badge-campaign-type badge-" +
                                key +
                                (this.state.filterTypes[key]
                                  ? " checked"
                                  : " not-checked")
                              }
                              onClick={() => this.handleTypesFilterClick(key)}
                            >
                              {CAMPAIGN_TYPES_FRIENDLY_NAMES[key]}
                            </span>

                            /*<div
                              key={index}
                              className={
                                "btn btn-primary btn-margin " +
                                (this.state.filterTypes[key]
                                  ? "checked"
                                  : "not-checked")
                              }
                              onClick={() => this.handleTypesFilterClick(key)}
                            >
                              {CAMPAIGN_TYPES_FRIENDLY_NAMES[key]}
                            </div>*/
                          )
                      )}
                    </div>
                  </div>

                  <div className="mt-2 mb-4">
                    <p className="text-bold">{strings.shared.status}</p>
                    <div className="btns-container mt-2">
                      {Object.keys(this.state.filterStatus).map(
                        (key, index) => (
                          <span
                            key={index}
                            className={
                              "mr-1 pointer badge badge-status-filter-selector" +
                              (this.state.filterStatus[key]
                                ? " checked"
                                : " not-checked")
                            }
                            onClick={() => this.handleStatusFilterClick(key)}
                          >
                            {CAMPAIGN_STATUS_FRIENDLY_NAMES[key]}
                          </span>

                          /*<div
                            key={index}
                            className={
                              "btn btn-primary btn-margin " +
                              (this.state.filterStatus[key]
                                ? "checked"
                                : "not-checked")
                            }
                            onClick={() => this.handleStatusFilterClick(key)}
                          >
                            {CAMPAIGN_STATUS_FRIENDLY_NAMES[key]}
                          </div>*/
                        )
                      )}
                    </div>
                  </div>

                  <div className="mt-2 mb-4">
                    <p className="text-bold">Fecha</p>
                    <div className="input-container fechas mt-1">
                      <label
                        className={!this.state.errorFechaDesde ? "error" : ""}
                        htmlFor="filterStartDate"
                      >
                        {strings.shared.from}
                      </label>
                      <input
                        type="text"
                        className="nxt-text-input"
                        placeholder={
                          strings.campaignList.filterDateFormatPlaceholder
                        }
                        name="filterStartDate"
                        onChange={this.changeDate}
                        value={this.state.filterStartDate}
                      />
                      <label
                        className={!this.state.errorFechaHasta ? "error" : ""}
                        htmlFor="filterEndDate"
                      >
                        {strings.shared.to}
                      </label>
                      <input
                        type="text"
                        className="nxt-text-input"
                        placeholder={
                          strings.campaignList.filterDateFormatPlaceholder
                        }
                        name="filterEndDate"
                        onChange={this.changeDate}
                        value={this.state.filterEndDate}
                      />
                    </div>
                  </div>

                  <div className="mt-2 mb-2 text-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        this.applyFilters();
                        this.setState({ showFilters: !this.state.showFilters });
                      }}
                    >
                      {strings.campaignList.filter}
                    </button>
                    <button
                      className="btn btn-primary ml-2"
                      onClick={() => {
                        this.clearFilters();
                      }}
                    >
                      {strings.campaignList.reset}
                    </button>
                  </div>
                </div>
              )}

              <div className="campaign-list mt-3">
                <div className="grid-campaigns-container">
                  <div className="cell header text-bold">
                    {strings.shared.type}
                  </div>
                  <div className="cell header text-bold">
                    {strings.shared.campaign}
                  </div>
                  <div className="cell header text-bold">
                    {strings.shared.status}
                  </div>
                  <div className="cell header text-bold align-end">
                    {strings.shared.options}
                  </div>
                </div>
                {this.state.filteredCampaigns.map((option, index) => (
                  <div
                    key={`row_${index}`}
                    className="grid-campaigns-container"
                  >
                    <div
                      key={`cType_${index}`}
                      className={
                        "cell nombre " + (index % 2 === 0 ? "even" : "odd")
                      }
                    >
                      <span
                        className={
                          "mr-1 badge badge-campaign-type badge-" + option.type
                        }
                      >
                        {CAMPAIGN_TYPES_FRIENDLY_NAMES[option.type]}
                      </span>
                    </div>
                    <div
                      key={`cName_${index}`}
                      className={
                        "cell nombre " + (index % 2 === 0 ? "even" : "odd")
                      }
                    >
                      {option.status === "created" ? (
                        <div>
                          <span className="text-bold">
                            {getFriendlyTitle(option.name)}
                          </span>
                          <p className="item-detail">
                            {this.getDetails(option)}
                          </p>
                        </div>
                      ) : option.status === "closed" ||
                        option.status === "scheduled" ? (
                        <Link
                          to={"/admin/campaigndetail/" + option.id}
                          className="link"
                        >
                          <span className="text-bold">
                            {getFriendlyTitle(option.name)}
                          </span>
                          <p className="item-detail">
                            {this.getDetails(option)}
                          </p>
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      key={`cStatus_${index}`}
                      className={
                        "cell estado " + (index % 2 === 0 ? "even" : "odd")
                      }
                    >
                      {option.status === "created"
                        ? strings.campaignList.status.created
                        : option.status === "closed"
                        ? `${
                            strings.campaignList.status.finished
                          } ${option.end_date.slice(0, 10)}`
                        : `${
                            strings.campaignList.status.in_progress
                          } ${option.end_date.slice(0, 10)}`}
                    </div>
                    <div
                      key={`cOpt_${index}`}
                      className={
                        "cell opcion align-end " +
                        (index % 2 === 0 ? "even" : "odd")
                      }
                    >
                      {option.status === "created" ? (
                        <Link
                          to="#"
                          onClick={() => this.removeCampaign(option)}
                          className="option-btn option-btn--red"
                        >
                          <i
                            className="icon-bin"
                            title={strings.shared.remove}
                          />
                        </Link>
                      ) : option.status === "closed" ||
                        option.status === "scheduled" ? (
                        <Link
                          to={"/admin/campaigndetail/" + option.id}
                          className="option-btn link"
                        >
                          <i
                            className="icon-zoom-in"
                            title={strings.shared.check}
                          />
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CampaignList;
